<template>
  <div class="row">

    <div class="col-12">

      <card>
        <div slot="header" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <h4 class="card-title">Video Category List</h4>
          <div class="d-flex justify-content-start">

            <router-link v-if="$store.getters['auth/haveOneOfPermissions'](['ar/video-categories/create'])"
                        :to="'/video-categories/create/'+lang"
                        class="btn btn-info btn-wd">
              Add New
              <span class="btn-label">
                      <i class="fa fa-plus"></i>
                  </span>
            </router-link>
          </div>

        </div>

        <div>
          <general-data-table
            ref="table"
            :method="'POST'"
            :row-key="'id'"
            :lang="lang"
            :key="componentKey"
            @reorder="reorder($event)"
            :api-url="'video-categories/index'">
            <el-table-column v-for="column in tableColumns"
                             :key="column.label"
                             :min-width="column.minWidth"
                             :align="column.align"
                             :sortable="column.sortable"
                             :prop="column.value"
                             :label="column.label">
            </el-table-column>

            <el-table-column
              :min-width="120"
              align="center"
              label="Active">
              <template slot-scope="props">

                <l-switch v-if="$store.getters['auth/haveOneOfPermissions'](['ar/video-categories/change-active-status']) ||
                                $store.getters['auth/checkAccessOwner']('restricted_own/ar/video-categories/change-active-status', props.row.creator_id)"
                          v-model="props.row.is_active" @input="handleActiveStatus(props.row)">
                  <i class="fa fa-check" slot="on"></i>
                  <i class="fa fa-times" slot="off"></i>
                </l-switch>

              </template>
            </el-table-column>

            <el-table-column
              :min-width="120"
              fixed="right"
              align="center"
              label="Actions">
              <template slot-scope="scope">

                <router-link v-if="$store.getters['auth/haveOneOfPermissions'](['ar/video-categories/update']) ||
                                     $store.getters['auth/checkAccessOwner']('restricted_own/ar/video-categories/update', scope.row.creator_id)"
                             v-tooltip.top-center="'Edit'"
                             class="btn-warning btn-simple btn-link"
                             :to="'/video-categories/edit/'+scope.row.id+'/'+lang">
                  <i class="fa fa-edit"></i></router-link>

                <router-link  v-if="$store.getters['auth/haveOneOfPermissions'](['video_categories_ar_logs_view'])"
                              v-tooltip.top-center="'Log'"
                              class="btn-info btn-simple btn-link"
                              :to="'/logs/video_categories_' + lang+'_'+scope.row.id"><i class="fa fa-eye"></i></router-link>

                <a v-if="$store.getters['auth/haveOneOfPermissions'](['ar/video-categories/delete']) ||
                           $store.getters['auth/checkAccessOwner']('restricted_own/ar/video-categories/delete', scope.row.creator_id)"
                   v-tooltip.top-center="'Delete'"
                   class="btn-danger btn-simple btn-link"
                   @click="openDeleteModal(scope.row.id)"><i class="fa fa-times"></i></a>
              </template>
            </el-table-column>

          </general-data-table>
        </div>

      </card>


      <delete-modal :visible="deleteModalVisibility"
                    :message="'Are you sure you want to delete this category?'"
                    @close="closeDeleteModal()"
                    @confirm="confirmDeleteModal()">
      </delete-modal>


    </div>

  </div>
</template>
<script>
import {TableColumn} from 'element-ui'
import {NewPagination as NLPagination} from '@/components'
import {DeleteModal as DeleteModal} from '@/components'
import {Switch as LSwitch} from '@/components'
import GeneralDataTable from "@/components/GeneralDataTable";
import FgSelect from "@/components/Inputs/formGroupSelect";
import ExportModal from '@/components/ExportModal/ExportModal.vue';


export default {
  components: {
    NLPagination,
    DeleteModal,
    [TableColumn.name]: TableColumn,
    LSwitch,
    GeneralDataTable,
    FgSelect,
    ExportModal
  },

  data() {

    return {
      tableColumns: [
        {label: 'Title', value: 'title', minWidth: '200', align: 'center'},
        {label: 'URL', value: 'url', minWidth: '200', align: 'center'},
      ],
      modals: {
        basic: false,
        centered: false,
        custom: false,
        confirm: false
      },

      deleteModalVisibility: false,
      toDeleteId: undefined,
      lang: 'ar',
      componentKey: 0,
    }

  },
  mounted() {
    this.$refs.table.createDragAndDropSorting();
  },
    created(){
        const path = window.location.href;
        this.lang = path.substring(path.lastIndexOf('/') + 1)
    },
  methods: {
    handleActiveStatus(row) {
      let data = {
        'is_active': row.is_active,
        'lang': this.lang,
      }
      this.axios.post("video-categories/change-active-status/" + row.id, data).then((response) => {
        this.$notify({
          message: "Category updated successfully",
          timeout: 1000,
          type: 'success'
        });
      }).catch((error) => {
        console.log(error)
      })
    },

    openDeleteModal(id) {
      this.deleteModalVisibility = true;
      this.toDeleteId = id;
    },

    closeDeleteModal() {
      this.deleteModalVisibility = false;
    },

    confirmDeleteModal() {

      let data = {
          'id': this.toDeleteId,
          'lang': this.lang,
      }
      this.axios.delete("video-categories/delete", {headers: {}, data}).then((response) => {
        this.$notify({
          message: "Category deleted successfully",
          timeout: 1000,
          type: 'success'
        });

        this.$refs.table.getData("updateData");

      }).catch((error) => {
        this.$notify({
          message: error.response.data.message,
          timeout: 2000,
          type: 'danger'
        })
      })

      this.closeDeleteModal();
      this.toDeleteId = undefined;
    },
    async reorder(data) {
      try {
          let body = {};
          let orderData = [];
          data.forEach((row, i) => {
              orderData.push({"id": row.id, "sort_order": i + 1})
          });
          body = {
              lang: this.lang,
              data: orderData,
          }

        await this.axios.post("video-categories/re-order", body);

        this.$notify({
          message: "order updated successfully",
          timeout: 1000,
          type: 'success'
        });

      } catch (error) {
        this.$notify({
          message: error.response.data.message,
          timeout: 2000,
          type: 'danger'
        })
      }
    },

    openModal (name) {
      this.modals[name] = true
    },
    closeModal (name) {
      this.modals[name] = false
    },

  },

}
</script>
